<template>
  <validation-provider :name="labelName" :rules="rules">
    <div class="c-input" slot-scope="ProviderProps">
      <div class="c-select" :class="{ hasErr: ProviderProps.errors[0] }">
        <slot name="label" />
        <select v-model="inputedVal" :name="name">
          <option
            v-if="placeholder"
            value=""
            disabled
            selected
            style="display: none"
          >
            {{ placeholder }}
          </option>
          <slot></slot>
        </select>
        <span class="c-select__icon"></span>
      </div>
      <p class="c-input__errMsg">{{ ProviderProps.errors[0] }}</p>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    change: {
      type: Function,
    },
    labelName: {
      type: String,
    },
    rules: {
      type: String,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.$emit("update:value", this.inputedVal);
      if (this.change) {
        this.change();
      }
    },
  },
  methods: {
  },
  mounted() {
    this.$emit("update:value", this.inputedVal);
  },
};
</script>
