<template>
  <validation-provider ref="validateInputtext" :name="labelName" :rules="rules">
    <div class="c-input" slot-scope="ProviderProps" :class="{ hasErr: ProviderProps.errors[0] }">
      <slot />
      <input
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        v-model="inputedVal"
        @input="$emit('update:value', $event.target.value)"
        @blur="(e) => blurFunc(e)"
      />
      <span
        v-if="tooltip"
        class="c-inputTooltip"
        :class="{
          isOk: isValid,
        }"
      >
        {{ tooltipMsg }}
      </span>
      <p class="c-input__errMsg">{{ ProviderProps.errors[0] }}</p>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    inputType: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    blur: {
      type: Function,
    },
    change: {
      type: Function,
    },
    labelName: {
      type: String,
    },
    rules: {
      type: String,
    }
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.changeHandler();
      if (this.change) {
        this.change();
      }
    },
  },
  computed: {
    tooltipMsg() {
      return this.isValid ? "OK" : this.tooltip;
    },
  },
  methods: {
    async changeHandler() {
      // 入力値がない場合は、tooltipを表示させる
      if(this.inputedVal.length === 0) {
        this.isValid = false;
        return;
      }
      const validationResult = await this.$refs.validateInputtext.validate();
      if (!validationResult.valid) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
    blurFunc(e) {
      if (this.blur) {
        this.blur(e);
      }
    },
  },
  mounted() {
    this.changeHandler();
  },
};
</script>
