<template>
    <validation-provider ref="validateTextarea" :name="labelName" :rules="rules">
      <div class="c-input" slot-scope="ProviderProps" :class="{ hasErr: ProviderProps.errors[0] }">
        <textarea
          :placeholder="placeholder"
          :rows="rows"
          v-model="inputedVal"
          @input="$emit('update:value', $event.target.value)"
        ></textarea>
        <span v-if="tooltip" class="c-inputTooltip">
          {{ tooltipMsg }}
        </span>
        <p class="c-input__errMsg">{{ ProviderProps.errors[0] }}</p>
        <slot></slot>
      </div>
    </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    rows: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    change: {
      type: Function,
    },
    labelName: {
      type: String,
    },
    rules:{
      type: String,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
    };
  },
  watch: {
    value() {
      this.inputedVal = this.value;
    },
    inputedVal() {
      this.changeHandler();
      if (this.change) {
        this.change();
      }
    },
  },
  computed: {
    tooltipMsg() {
      return this.isValid ? "OK" : this.tooltip;
    },
  },
  methods: {
    async changeHandler() {
      // 入力値がない場合は、tooltipを表示させる
      if(this.inputedVal.length === 0) {
        this.isValid = false;
        return;
      }
      const validationResult = await this.$refs.validateTextarea.validate();
      if (!validationResult.valid) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
  },
  mounted() {
    this.changeHandler();
  },
};
</script>
